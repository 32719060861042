export const GET_SETTING_DATA = "GET_SETTING_DATA";
export const GET_WITHDRAWAL = "GET_WITHDRAWAL";
export const ADD_PAYMENT_GATEWAY = "ADD_PAYMENT_GATEWAY";
export const EDIT_PAYMENT_GATEWAY = "EDIT_PAYMENT_GATEWAY";
export const ACTIVE_SWITCH_PAYMENT_GATEWAY = "ACTIVE_SWITCH_PAYMENT_GATEWAY";
export const EDIT_SETTING = "EDIT_SETTING";
export const SWITCH_SETTING = "SWITCH_SETTING";

export const GET_ADS_DATA = "GET_ADS_DATA";
export const IS_ADS_CHANGE = "IS_ADS_CHANGE";
export const ADS_API_DATA = "ADS_API_DATA";

export const DELETE_WITHDRAW = "DELETE_WITHDRAW";


export const GET_ADS_REWARD = "GET_ADS_REWARD";
export const ADD_ADS_REWARD = "ADD_ADS_REWARD";
export const EDIT_ADS_REWARD = "EDIT_ADS_REWARD";
export const DELETE_ADS_REWARD = "DELETE_ADS_REWARD";



export const GET_DAILY_REWARD = "GET_DAILY_REWARD";
export const ADD_DAILY_REWARD = "ADD_DAILY_REWARD";
export const EDIT_DAILY_REWARD = "EDIT_DAILY_REWARD";
export const DELETE_DAILY_REWARD = "DELETE_DAILY_REWARD";